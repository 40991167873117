<template>
  <a-drawer width="100%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车道名称" prop="laneName" v-if="formType === 2">
        <a-input v-model="form.laneName" placeholder="请输入车道名称" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <div class="lane">
      <baidu-map
        class="bm-view"
        :center="center"
        :zoom="zoom"
        @ready="initMap"
        :continuous-zoom="true"
        :scroll-wheel-zoom="true"
      >
        <div v-for="item in laneData" :key="item.id">
          <bm-polyline
            :path="item.baiduPoints"
            stroke-color="blue"
            :stroke-opacity="0.5"
            :stroke-weight="2"
            :editing="false"
            @click="setLaneId($event, item.laneId)"
          />

          <!-- <bm-marker
            v-if="item.baiduPoints.length > 0"
            :position="item.baiduPoints[0]"
            :icon="{ url: startIconUrl, size: { width: 32, height: 32 } }"
          ></bm-marker>

          <bm-marker
            v-if="item.baiduPoints.length > 0"
            :position="item.baiduPoints[item.baiduPoints.length - 1]"
            :icon="{ url: endIconUrl, size: { width: 32, height: 32 } }"
          ></bm-marker> -->
        </div>
      </baidu-map>
    </div>
  </a-drawer>
</template>

<script>
import { getRaLane, addRaLane, updateRaLane, getLanes } from '@/api/iot/raLane'
import { mapGetters } from 'vuex'
export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        laneName: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      center: { lng: 0, lat: 0 },
      zoom: 3,
      laneData: [],
      startPos: { lng: 0, lat: 0 },
      endPos: { lng: 0, lat: 0 },
      mapIsLoad: false
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapGetters(['userTenant'])
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        laneName: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      console.log('handleUpdate')
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRaLane(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })

      getLanes().then(res => {
        this.setMapData(res)
      })
    },
    setMapData(res) {
      if (res.length === 0) {
        return
      }
      if (!this.mapIsLoad) {
        setTimeout(() => {
          this.setMapData(res)
        }, 100)
      } else {
        setTimeout(() => {
          this.laneData = res
          this.$forceUpdate()
        }, 300)
      }
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRaLane(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addRaLane(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    initMap() {
      this.zoom = 90
      this.mapIsLoad = true
      this.center.lng = 118.09790231048498
      this.center.lat = 24.58287801501669
    },
    setLaneId(e, param) {
      console.log('e', e)
      console.log('param', param)
      alert(param)
    }
  }
}
</script>

<style lang="less">
.lane {
  width: 100%;
  height: calc(100vh - 20px);
  margin-bottom: 30px;
  .bm-view {
    width: 100%;
    height: 100%;
    border: 1px solid #013a07;
  }
}
.bottom-control {
  z-index: 99999 !important;
}
</style>
